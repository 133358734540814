// src/pages/ThreateningText.js
import React from 'react';

const ThreateningText = () => {
    return (
        <div>
            <h1>Project Overview: Threat Detection Model</h1>
            <p>
                This project is a binary classification model to identify threats in text data. 
                The process encompasses data preparation, model training, hyperparameter tuning, and deployment, resulting in a 
                model that classifies whether a comments is a threat or not.
            </p>

            <h3>Key Steps:</h3>
            <ul>
                <li><strong>Data Preparation:</strong> Load and sample text data and preprocess.</li>
                <li><strong>Model Training:</strong> Utilise a Random Forest Classifier with hyperparameter tuning to enhance performance.</li>
                <li><strong>Evaluation:</strong> Assess the model using metrics like accuracy, precision, and recall to ensure effectiveness.</li>
            </ul>

            <h3>Next Steps:</h3>
            <p>
                Further hyperparameter tuning and model exploration could improve performance, while incorporating advanced 
                NLP techniques and larger datasets may enhance detection accuracy.
            </p>

            <a
                href="https://colab.research.google.com/drive/16R2r91xPen_UnfiZWK8qnJ0QTP7m3OJH"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: '24px', textDecoration: 'underline', marginTop: '20px' }}
            >
                Open Threatening Text Notebook
            </a>

        </div>
    );
};

export default ThreateningText;
