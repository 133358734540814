// src/pages/MountOusleyApps.js
import React from 'react';

const MountOusleyApps = () => {
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', padding: '20px', boxSizing: 'border-box' }}>
            <h1>Mount Ousley Project: Digital Engineering Apps</h1>
            <p>
                The Mount Ousley Project leverages cutting-edge digital engineering tools to streamline and automate complex workflows. The following applications were developed to enhance productivity, reduce manual tasks, and ensure greater accuracy.
            </p>

            {/* App 1: FME and Parametric Modelling */}
            <section>
                <h2>FME and Parametric Modelling</h2>
                <p>
                    This app uses **Feature Manipulation Engine (FME)** for data translation and automated parametric modelling.
                    By integrating multiple data sources and applying rule-based modelling, it ensures seamless design updates and data integrity.
                </p>
                <h4>Key Features:</h4>
                <ul>
                    <li>Automated model updates based on design changes</li>
                    <li>Data validation and error detection</li>
                    <li>Efficient handling of large-scale infrastructure data</li>
                </ul>
            </section>

            {/* App 2: Data Translation */}
            <section>
                <h2>Data Translation</h2>
                <p>
                    Designed to convert and standardize data across multiple formats, this app ensures compatibility between software platforms.
                    It is particularly effective for integrating CAD, GIS, and BIM data.
                </p>
                <h4>Key Features:</h4>
                <ul>
                    <li>Supports DWG, IFC, and GIS formats</li>
                    <li>Automated error detection and reporting</li>
                    <li>Seamless data exchange across platforms</li>
                </ul>
            </section>

            {/* App 3: Takeoff Automation */}
            <section>
                <h2>Takeoff Automation and Chainage Generation</h2>
                <p>
                    This app automates the process of generating accurate takeoffs and chainage calculations, reducing human error and manual effort.
                    It uses geospatial data to create detailed material quantities and construction-ready outputs.
                </p>
                <h4>Key Features:</h4>
                <ul>
                    <li>Automated material quantity calculations</li>
                    <li>Accurate chainage and sign generation</li>
                    <li>Integration with construction management systems</li>
                </ul>
            </section>

            <h3>Project Impact</h3>
            <p>
                The implementation of these applications on the Mount Ousley Project has led to significant time savings, improved data accuracy, and enhanced collaboration between teams.
                These digital engineering solutions serve as a benchmark for future infrastructure projects.
            </p>
        </div>
    );
};

export default MountOusleyApps;