// src/pages/DigitalEngineeringHub.js
import React from 'react';

const DigitalEngineeringHub = () => {
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', padding: '20px', boxSizing: 'border-box' }}>
            <h1>Digital Engineering Hub</h1>
            
            <h2>Project Overview</h2>
            <p>
                Developed the Digital Engineering Hub—a comprehensive React-based digital platform that unifies and streamlines critical information for digital engineering across New Zealand and Australia. This hub served as the central point for digital initiatives, fostering strategic digital transformation through a modular and scalable design.
            </p>

            <h3>Key Features & Components</h3>
            <ul>
                <li><strong>Staff Repository:</strong> Integrated profiles showcasing skills, roles, and expertise for effective collaboration.</li>
                <li><strong>Technical Resources:</strong> Curated tools, documentation, and best practices supporting modular workflows.</li>
                <li><strong>Digital Initiatives:</strong> Continuous improvement projects aligned with operational strategies.</li>
                <li><strong>Modular Structure:</strong> Scalable and flexible design for rapid deployment using React components.</li>
                <li><strong>Coordination Framework:</strong> Seamless communication between IT, staff, and stakeholders.</li>
            </ul>

            <h3>React App Structure</h3>
            <ul>
                <li><strong>Projects:</strong> A dedicated section listing key projects.</li>
                <li><strong>Threatening Text Detection Model:</strong> AI-powered tool identifying harmful language.</li>
                <li><strong>AI Image Scanner:</strong> Machine learning-based image analysis module for asset management.</li>
            </ul>

            <h3>Project Impact</h3>
            <p>
                The Digital Engineering Hub streamlined resource management and technical operations across NZ/AU, demonstrating a strategic vision for digital transformation. By integrating AI projects into a unified React framework, it showcased the benefits of modular design and a user-centric approach.
            </p>
        </div>
    );
};

export default DigitalEngineeringHub;
