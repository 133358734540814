// src/App.js
import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ProjectList from './pages/ProjectList';
import ThreateningText from './pages/ThreateningText';
import DigitalEngineeringHub from './pages/DigitalEngineeringHub';
import MountOusleyApps from './pages/MountOusleyApps';

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/projects" exact component={ProjectList} />
          <Route path="/threatening-text" exact component={ThreateningText} />
          <Route path="/digital-engineering-hub" exact component={DigitalEngineeringHub} />
          <Route path="/MountOusleyApps" exact component={MountOusleyApps} />
          <Redirect from="/" to="/projects" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
